
.projectCard
    position: relative
    cursor: pointer
    height: 100%
    width: 100%
    .groupBtn
        position: relative
        padding: 1rem
    .backgroundImage
        img
            aspect-ratio: 9 / 2
    .backgroundImage
        position: relative
        max-height: 100%
        max-width: 100%
        height: inherit
        z-index: 0
        background: #737791
        img
            @media screen and (max-width: 600px)
                aspect-ratio: 1 / 1
    .wrapper
        min-height: inherit
        position: absolute
        inset: 0
        z-index: 1
        height: 100%
        .top
            padding: 1rem
            text-align: left
        .desc
            padding: 10px
            background: #C1C0F0
            text-align: center
            hyphens: auto
            p, span
                // color: #000
                font-size: 1.15rem
                line-height: 24px
            .name
                font-size: 1.35rem
                font-weight: 600
                // white-space: nowrap
                // display: -webkit-box
                // -webkit-box-orient: vertical
                // -webkit-line-clamp: 2
                overflow: hidden
                text-overflow: ellipsis
    .joinBanner
        position: absolute
        z-index: 1
        left: 0
        top: 0
        bottom: 0
        background: #737791
        width: 40px
        display: flex
        align-items: center
        justify-content: center
    .circleTop
        position: absolute
        z-index: 1
        left: 0
        top: 0
        background: #fff
        border-radius: 50%
        height: 28px
        width: 28px
        margin-left: -16px
        margin-top: -16px
    .circleBottom
        position: absolute
        z-index: 1
        left: 0
        bottom: 0
        background: #fff
        border-radius: 50%
        height: 28px
        width: 28px
        margin-left: -16px
        margin-bottom: -16px

    @media screen and (max-width: 1200px)
        .wrapper
            .desc
                padding: 0.5rem
                text-align: center
                p, span
                    font-size: 1rem
                .name
                    font-size: 1rem
    @media screen and (max-width: 600px)
        .circleTop
            display: none
        .circleBottom
            display: none
