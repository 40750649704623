.root
    >div>
        button
            border: 2px solid
            color: #737791
            border-radius: 40px
            text-transform: lowercase
            height: 37px
            font-size: 16px
            font-weight: 700
            box-sizing: border-box
