$primary-color: #737791

.loading
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    inset: 25%
    & > span
        color: $primary-color
.body
    display: flex
    justify-content: center
    align-items: center
    padding: 50px
    @media screen and (max-width: 1200px)
        padding: 20px 0 10px
    .title
        font-weight: 600
        font-size: 24px
        line-height: 30px
        text-align: center
        letter-spacing: 0.01em
        color: #0D0D0D
    .avatar
        width: 127px
        height: 127px
    .mainContent
        padding: 40px
        background: #F5F5F5
        border-radius: 30px
        @media screen and (max-width: 1200px)
            padding: 20px
    .slot
        background: #737791
        color: #FFF
        border-radius: 30px
        padding: 8px 16px
    .max3Lines
        display: -webkit-box
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical
        overflow: hidden
        overflow-wrap: break-word
    .tableNumber
        color: #737791
    .primaryButton
        width: 100%
        padding: 14px 20px
        background: #737791
        color: #FFF
    .secondaryButton
        width: 100%
        padding: 14px 20px
        background: #737791
        color: #FFF
