.content
    .header
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 1.5rem
        margin-bottom: 2rem
        .title
            margin: 0
            font-weight: 500
        .userSection
            margin-bottom: 3rem
        .status
            background: #2AB740
            color: #FFF
            width: fit-content
            border-radius: 30px
            padding: 6px 18px
            margin-bottom: 6px
            font-size: 12px
            cursor: auto
        @media screen and (max-width: 1200px)
            gap: 0.5rem
            box-shadow: none
        .desc
            font-size: 17px
            line-height: 36px
        .button
            // background: #737791
            // color: #FFF
            // border-radius: 30px
            padding: 14px 24px
            margin-bottom: 3rem
            width: 100%
            font-size: 14px
            display: none
            // &:hover
            //     background: #b976ff
            @media screen and (max-width: 1200px)
                display: block

.uploadZone
    background: #F5F5F5
    border: 1px dashed #D9D9D9
    border-radius: 30px
    width: fit-content
    max-width: calc(100% - 8px)
    height: 15rem
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    cursor: pointer
    margin: 1.5rem auto 0
    text-align: center
    padding: 4px
    @media screen and (max-width: 1200px)
        margin-top: 0
    &:hover
        border: 1px dashed #000000
    .file
        display: flex
        align-items: center
        gap: 1rem
        @media screen and (max-width: 1200px)
            flex-direction: column
            align-items: flex-start
            gap: 0
        .fileInfo
            display: flex
            align-items: center
            gap: 0.5rem
        .icon
            color: #737791
            width: 1.5rem !important
            height: 1.5rem !important
        .name
            cursor: pointer
            &:hover
                text-decoration: underline

.button
    display: flex
    justify-content: center
    margin-top: 2.5rem
    .submitButton
        // border-radius: 36px
        font-size: 15px
        // background-color: #000
        padding: 1rem 5rem
        // width: fit-content
        width: 100%
        text-transform: none
        // &:hover
        //     background: #5a5a5a
        // &:disabled
        //     background: #F6F8FE
        @media screen and (max-width: 1200px)
            width: 100%
    .secondaryButton
        border-radius: 36px
        font-size: 15px
        background-color: #F6F8FE
        color: #000
        padding: 1rem 5rem
        // width: fit-content
        width: 100%
        text-transform: none
        box-shadow: none
        &:hover
            background: #eeeeee
        &:disabled
            background: #F6F8FE
        @media screen and (max-width: 1200px)
            width: 100%

.inputAvatar
    display: none

.imageWrapper
    position: relative
    margin: 16px auto 0
    max-width: 350px
    @media screen and (max-width: 1200px)
        max-width: 250px
        margin: 16px auto 0

.image
    cursor: pointer
    width: 100%
    max-height: 250px
    @media screen and (max-width: 1200px)
        max-height: 250px
        max-width: 250px
    &:hover
        filter: brightness(1.2)

.cameraLabel
    position: absolute
    z-index: 99
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    right: 2%
    bottom: 2%
    border-radius: 50%
    background: #838383
    padding: 5px
    color: #fff
    opacity: 0.4
    &:hover
        opacity: 1
        background: #3e3e3e

.suggestions
    ::-webkit-scrollbar
        width: 7px
        height: 6px
    ::-webkit-scrollbar-thump
        background: #a0a0a0
        border-radius: 16px
