.content
    // background: #FFFFFF
    // box-shadow: 0px 2px 10px 0px rgba(19, 17, 32, 0.15)
    // border-radius: 30px
    // padding: 2rem
    @media screen and (max-width: 1200px)
        border-radius: 0
    .fullName
        overflow-wrap: anywhere
        text-overflow: ellipsis
        overflow: hidden
        display: -webkit-box
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical
    .title
        margin: 0 0 16px 0
        font-weight: 500
    .header
        display: flex
        align-items: center
        gap: 1.5rem
        margin-bottom: 2rem

        .userSection
            margin-bottom: 3rem
        .status
            background: #2AB740
            color: #FFF
            width: fit-content
            border-radius: 30px
            padding: 6px 18px
            margin-bottom: 6px
            font-size: 12px
            cursor: auto
        @media screen and (max-width: 1200px)
            z-index: 2
            padding: 3rem 0 1rem
            border-radius: 30px 30px 0 0
            box-shadow: none
        .desc
            font-size: 17px
            line-height: 36px
        .button
            background: #737791
            color: #FFF
            border-radius: 30px
            padding: 14px 24px
            margin-bottom: 3rem
            width: 100%
            font-size: 14px
            display: none
            &:hover
                background: #b976ff
            @media screen and (max-width: 1200px)
                display: block

.button
    display: flex
    justify-content: center
    margin-top: 2.5rem
    .submitButton
        border-radius: 36px
        font-size: 15px
        background-color: #000
        padding: 1rem 5rem
        // width: fit-content
        width: 100%
        text-transform: none
        &:hover
            background: #5a5a5a
        &:disabled
            background: #F6F8FE
        @media screen and (max-width: 1200px)
            width: 100%
    .secondaryButton
        border-radius: 36px
        font-size: 15px
        background-color: #F6F8FE
        color: #000
        padding: 1rem 5rem
        // width: fit-content
        width: 100%
        text-transform: none
        box-shadow: none
        &:hover
            background: #eeeeee
        &:disabled
            background: #F6F8FE
        @media screen and (max-width: 1200px)
            width: 100%

.inputAvatar
    display: none

.imageWrapper
    position: relative
    margin-top: 16px
    @media screen and (max-width: 1200px)
        max-width: 250px

.image
    cursor: pointer
    width: 100%
    max-height: 250px
    @media screen and (max-width: 1200px)
        max-height: 250px
        max-width: 250px
    &:hover
        filter: brightness(1.2)

.cameraLabel
    position: absolute
    z-index: 99
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    right: 2%
    bottom: 2%
    border-radius: 50%
    background: #838383
    padding: 5px
    color: #fff
    opacity: 0.4
    &:hover
        opacity: 1
        background: #3e3e3e

.memberSection
    padding: 40px
    background: #F5F5F5
    border-radius: 30px
    @media screen and (max-width: 567px)
        flex-direction: column !important
        gap: 12px
        align-items: center

.slot
    background: #737791
    color: #FFF
    border-radius: 30px
    padding: 8px 16px
.max3Lines
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
    overflow-wrap: break-word
.tableNumber
    color: #737791

.date
    font-size: 15px
    padding: 12px 18px
    background: #F5F5F5
    border-radius: 4px
    cursor: pointer
    text-transform: capitalize
    &:hover
        background: #737791
        color: #FFF

.date.selected
    background: #737791
    color: #FFF

.showMore
    width: fit-content
    border: 1px solid #737791
    color: #737791
    // border-radius: 30px
    padding: 8px 16px
    cursor: pointer
    margin: 14px auto 0
    &:hover
        background: #737791
        color: #FFF

.status
    text-align: center
    border-radius: 30px
    padding: 8px 16px
    font-size: 14px
    letter-spacing: 0.5px
    color: white
    @media screen and (max-width: 1200px)
        width: fit-content !important

.buttonGroup
    & > *
        min-width: 40px !important
        max-width: 40px
        height: 40px
    .decline
        border-color: #737791
        color: #737791
        &:hover
            background: #dcdcdc
            border-color: #737791
    .approve
        background: #737791
        &:hover
            opacity: 0.8
            background: #737791
