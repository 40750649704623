$primary-color: #737791

.loading
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    inset: 25%
    & > span
        color: $primary-color
.body
    display: flex
    flex-direction: column
    gap: 32px
    width: 80%
    margin: auto
    font-size: 16px
    // & > div:not(:first-of-type)
    //     margin-top: 1rem
    //     border-top: 1.5px solid #e3e3e3
    .service
        position: relative
        .title
            font-size: 20px
            font-weight: 600
            text-align: center
            margin: 1rem 0
        .desc
            font-size: 17px
            padding-bottom: 1rem
    .packageTitle
        font-size: 24px
        font-weight: 600
        text-align: center
        color: $primary-color
        margin: 1rem 0
    .name
        color: #060695
        font-size: 20px
        margin-bottom: 8px
    .tag
        padding: 4px 24px
        background: #cfeeff
        color: #17b8ff
        width: fit-content
        border-radius: 2px
    .desc
        hyphens: auto

    .memberType
        color: #2f79ff
        font-weight: bold
        font-size: 18px
    .memberName
        color: #6e1da7
