$primary-color: #737791

.loading
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    inset: 25%
    & > span
        color: $primary-color
.body
    display: flex
    flex-direction: column
    gap: 16px
    width: 90%
    margin: auto
    & > div:not(:first-of-type)
        margin-top: 1rem
        border-top: 1.5px solid #e3e3e3
    .service
        position: relative
        .title
            font-size: 20px
            font-weight: 600
            text-align: center
            margin: 1rem 0
        .desc
            font-size: 17px
            padding-bottom: 1rem
    .packageTitle
        font-size: 24px
        font-weight: 600
        text-align: center
        color: $primary-color
        margin: 1rem 0
    .packageDescription
        hyphens: auto
        color: rgba(0, 0, 0, 0.6)
