$primary-color: #737791

.content
    .header
        display: flex
        align-items: center
        gap: 8px
        .title
            margin: 0
            font-weight: bold
