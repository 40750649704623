.root
    display: flex
    flex-direction: column
    justify-content: center
    gap: 65px
    height: 100%
    .title 
        font-size: 40px
        font-weight: 700
        text-transform: uppercase
        margin: 0
        background: linear-gradient(103.07deg, #79512D -116.28%, #79512D -54.53%, #C2965D 125.98%, #A1641F 308.1%, #A1641F 340.15%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
    .subTitle 
        font-size: 40px
        font-weight: 400
        line-height: 1
        text-transform: uppercase
        margin: 0
        color: #141414
    .description
        display: block
        font-size: 20px
        font-weight: 300
        color: #141414
        margin-top: 24px
    .form
        display: flex
        flex-direction: column
        gap: 20px
        .submitButton
            font-size: 18px
            font-weight: 700
            line-height: 20px
            text-transform: uppercase
            padding: 20px 50px !important
        .loginYandex 
            font-size: 18px
            font-weight: 600
            color: #000
    .loginOr
        position: relative
        text-align: center
        & > hr
            border: 0
            border-top: 1px solid rgba(0,0,0,.1)
        & > span
            text-transform: uppercase
            font-size: 14px
            line-height: 14px
            color: #464646
            position: absolute
            display: block
            background-color: #fff
            top: 0
            left: 50%
            padding: 0 5%
            -ms-transform: translate(-50%)
            transform: translate(-50%)
            @media screen and (max-width: 1200px)
                background-color: #fff

//     @media screen and (max-width: 1200px)
//         width: 90%
//         gap: 48px
//         height: auto
//         margin: 92px auto
//     .title
//         font-size: 44px
//         @media screen and (max-width: 500px)
//             font-size: 36px
//     .description
//         font-size: 16px
//         color: #949598
//         padding-bottom: 45px
//         @media screen and (max-width: 500px)
//             font-size: 10px
//     .form
//         display: flex
//         flex-direction: column
//         gap: 2rem
//         .submitButton
//             border-radius: 36px
//             background-color: #000
//             padding: 0.75rem 2rem
//             margin: 1.25rem auto 1rem
//             width: 50%
//             &:hover
//                 background: #5a5a5a
//             &:disabled
//                 background: #F6F8FE
//                 border: 1px solid #bbb

