.content
    border-radius: 30px
    background: #F5F5F5
    padding: 10px 20px
    height: calc(100% - 20px)
.timeButton
    border-radius: 36px !important
    border-color: #737791 !important
    color: #737791 !important
    width: fit-content
    height: 30px
.slotBusy
    background-color: #73779169 !important
    color: white !important
    cursor: not-allowed !important
