.atom-button
    padding: 20px
    border: none
    outline: none
    color: #161616
    background-color: #EEEEEE
    cursor: pointer
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)
    &:hover
        background-color: darken(#EEEEEE, 5%)
    &.primary
        background-color: #737791
        &:hover
            background-color: darken(#737791, 5%)
    &.selected
        background-color: #161616
        color: #FFF
        &:hover
            background-color: darken(#161616, 5%)
