.root
    display: flex
    align-items: center
    gap: 8px
    flex-wrap: nowrap
    position: relative
    .calendar
        display: flex
        justify-content: space-between
        width: 100%
        gap: 8px
        flex-wrap: wrap
        .dateCell.selected
            background: #737791
            border-radius: 50%
            & div
                color: #FFF !important
        .dateCell
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            width: 2rem
            height: 2rem
            padding: 0.5rem
            cursor: pointer
            &:hover
                background: #737791
                border-radius: 50%
                & div
                    color: #FFF !important
            .month
                position: absolute
                top: -1.5rem
                color: #7B7B7D
                text-transform: capitalize
            .date
                font-size: 0.75rem
                color: #3C3C4399
            .numDate
                font-size: 1.2rem
                color: #333333
