
.projectCard
    position: relative
    borderRadius: 14px
    width: 330px
    height: 224px
    button
        // border: 1px solid #737791
        color: #737791
        background-color: white
        border-radius: 40px
        text-transform: lowercase
    .projectMainInfo
        flex-direction: row
        justify-content: flex-start
        align-items: center
        gap: 5px
        margin: 10px 0px
        .projectImage
            border-radius: 25px

    .cardContent
        background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2258%22%20viewBox%3D%220%200%2040%2058%22%20fill%3D%22none%22%3E%3Cg%20style%3D%22mix-blend-mode%3Amultiply%22%20opacity%3D%220.3%22%3E%3Cpath%20d%3D%22M11.5186%2026V32.0833H40.001V42.125H11.2692V48.2917H40.001V58H0.000976562V0H40.001V10.0833H11.5186V16.125H40.001V26H11.5186Z%22%20fill%3D%22%23BDBDBD%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')
        background-repeat: no-repeat
        background-position: right
        padding-bottom: 10px

    .projectName
        font-weight: 900
        font-size: 15px
        line-height: 17px
        margin-top: -5px
        width: 75%

    .projectNameSmall
        font-weight: 900
        font-size: 12px
        line-height: 13px
        margin-top: -5px
        width: 75%

    .projectOrg
        margin-top: 5px
        padding-left: 20px
        font-size: 12px
        line-height: 13.8px
        width: 80%
        &::before
            content: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2211%22 height%3D%228%22 viewBox%3D%220 0 11 8%22 fill%3D%22none%22%3E%3Cpath d%3D%22M3.85 5C2.563 5 0 5.66857 0 7V8H7.7V7C7.7 5.66857 5.137 5 3.85 5ZM1.287 6.85714C1.749 6.52571 2.8655 6.14286 3.85 6.14286C4.8345 6.14286 5.951 6.52571 6.413 6.85714H1.287ZM3.85 4C4.9115 4 5.775 3.10286 5.775 2C5.775 0.897143 4.9115 0 3.85 0C2.7885 0 1.925 0.897143 1.925 2C1.925 3.10286 2.7885 4 3.85 4ZM3.85 1.14286C4.3065 1.14286 4.675 1.52571 4.675 2C4.675 2.47429 4.3065 2.85714 3.85 2.85714C3.3935 2.85714 3.025 2.47429 3.025 2C3.025 1.52571 3.3935 1.14286 3.85 1.14286ZM7.722 5.03429C8.36 5.51429 8.8 6.15429 8.8 7V8H11V7C11 5.84571 9.075 5.18857 7.722 5.03429ZM7.15 4C8.2115 4 9.075 3.10286 9.075 2C9.075 0.897143 8.2115 0 7.15 0C6.853 0 6.578 0.0742857 6.325 0.2C6.6715 0.708571 6.875 1.33143 6.875 2C6.875 2.66857 6.6715 3.29143 6.325 3.8C6.578 3.92571 6.853 4 7.15 4Z%22 fill%3D%22%231B88FF%22/%3E%3C/svg%3E')
            position: absolute
            left: 20px

    .projectPlace
        padding-left: 20px
        margin-top: 5px
        font-size: 12px
        line-height: 13.8px
        width: 80%
        &::before
            content: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%229%22 height%3D%2213%22 viewBox%3D%220 0 9 13%22 fill%3D%22none%22%3E%3Cpath d%3D%22M4.5 0C2.01214 0 0 2.0345 0 4.55C0 7.9625 4.5 13 4.5 13C4.5 13 9 7.9625 9 4.55C9 2.0345 6.98786 0 4.5 0ZM1.28571 4.55C1.28571 2.756 2.72571 1.3 4.5 1.3C6.27429 1.3 7.71429 2.756 7.71429 4.55C7.71429 6.422 5.86286 9.2235 4.5 10.972C3.16286 9.2365 1.28571 6.4025 1.28571 4.55Z%22 fill%3D%22%231B88FF%22/%3E%3Cpath d%3D%22M4.5 6.175C5.3876 6.175 6.10714 5.44746 6.10714 4.55C6.10714 3.65254 5.3876 2.925 4.5 2.925C3.6124 2.925 2.89286 3.65254 2.89286 4.55C2.89286 5.44746 3.6124 6.175 4.5 6.175Z%22 fill%3D%22%231B88FF%22/%3E%3C/svg%3E')
            position: absolute
            left: 20px

    @media screen and (max-width: 1200px)

    @media screen and (max-width: 600px)
