.content
    min-height: calc(100vh - 10rem)
    & > div:not(.header)
        padding: 1rem 0 3rem
        background: transparent
        box-shadow: none
    .fullName
        overflow-wrap: anywhere
        text-overflow: ellipsis
        overflow: hidden
        display: -webkit-box
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical
    .header
        display: flex
        align-items: center
        gap: 1.5rem
        margin-bottom: 1rem
        .title
            margin: 0
            font-weight: 500
        .userSection
            margin-bottom: 3rem
        .status
            background: #2AB740
            color: #FFF
            width: fit-content
            border-radius: 30px
            padding: 6px 18px
            margin-bottom: 6px
            font-size: 12px
            cursor: auto
        @media screen and (max-width: 1200px)
            gap: 0.5rem
            box-shadow: none
        .desc
            font-size: 17px
            line-height: 36px
        .button
            padding: 14px 24px
            margin-bottom: 3rem
            width: 100%
            font-size: 14px
            display: none
            @media screen and (max-width: 1200px)
                display: block

.uploadZone
    background: #F5F5F5
    border: 1px dashed #D9D9D9
    border-radius: 30px
    width: fit-content
    max-width: calc(100% - 8px)
    height: 15rem
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    cursor: pointer
    margin-top: 1.5rem
    text-align: center
    padding: 4px
    &:hover
        border: 1px dashed #000000
    .file
        display: flex
        align-items: center
        gap: 1rem
        @media screen and (max-width: 1200px)
            flex-direction: column
            align-items: flex-start
            gap: 0
        .fileInfo
            display: flex
            align-items: center
            gap: 0.5rem
        .icon
            color: #737791
            width: 1.5rem !important
            height: 1.5rem !important
        .name
            cursor: pointer
            &:hover
                text-decoration: underline

.button
    display: flex
    justify-content: center
    margin-top: 2.5rem
    .submitButton
        border-radius: 36px
        font-size: 15px
        background-color: #000
        padding: 1rem 5rem
        // width: fit-content
        width: 100%
        text-transform: none
        &:hover
            background: #5a5a5a
        &:disabled
            background: #F6F8FE
        @media screen and (max-width: 1200px)
            width: 100%
    .secondaryButton
        border-radius: 36px
        font-size: 15px
        background-color: #F6F8FE
        color: #000
        padding: 1rem 5rem
        // width: fit-content
        width: 100%
        text-transform: none
        box-shadow: none
        &:hover
            background: #eeeeee
        &:disabled
            background: #F6F8FE
        @media screen and (max-width: 1200px)
            width: 100%

.inputAvatar
    display: none

.imageWrapper
    position: relative
    margin-top: 16px
    @media screen and (max-width: 1200px)
        max-width: 250px

.image
    cursor: pointer
    width: 100%
    max-height: 250px
    @media screen and (max-width: 1200px)
        max-height: 250px
        max-width: 250px
    &:hover
        filter: brightness(1.2)

.cameraLabel
    position: absolute
    z-index: 99
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    right: 2%
    bottom: 2%
    border-radius: 50%
    background: #838383
    padding: 5px
    color: #fff
    opacity: 0.4
    &:hover
        opacity: 1
        background: #3e3e3e

.memberSection
    padding: 40px
    background: #F5F5F5
    border-radius: 30px

.slot
    background: #737791
    color: #FFF
    border-radius: 30px
    padding: 8px 16px
.max3Lines
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
    overflow-wrap: break-word
.tableNumber
    color: #737791

.date
    font-size: 15px
    padding: 12px 18px
    background: #F5F5F5
    border-radius: 4px
    cursor: pointer
    text-transform: capitalize
    &:hover
        background: #737791
        color: #FFF

.date.selected
    background: #737791
    color: #FFF

.showMore
    width: fit-content
    border: 1px solid #737791
    color: #737791
    // border-radius: 30px
    padding: 8px 16px
    cursor: pointer
    margin: 14px auto 0
    &:hover
        background: #737791
        color: #FFF
