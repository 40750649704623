$primary-color: 737791

.root
    margin-top: 2.5rem
    display: flex
    align-items: center
    gap: 1.75rem
    position: relative
    @media screen and (max-width: 1200px)
        flex-direction: column
        justify-content: center
        gap: 1.25rem
        margin-top: 6.25rem
        margin-bottom: 3.5rem
    .titleMobile
        font-size: 2rem
        font-weight: 600
        display: none
        @media screen and (max-width: 1200px)
            display: flex
    .avatar
        height: 6.25rem
        width: 6.25rem
        font-size: 1.75rem
        background: $primary-color
        text-transform: uppercase
    .mainSection
        display: flex
        flex-direction: column
        @media screen and (max-width: 1200px)
            align-items: center
        .name
            font-size: 1.15rem
            font-weight: 600
            color: #0D0D0D
            text-transform: capitalize
        .email
            color: $primary-color
   
