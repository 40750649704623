.root
    margin-top: 2.5rem !important
    padding: 0 1.5rem !important
    @media screen and (max-width: 1200px)
        margin-top: 10rem !important
    .search
        width: 100%
        margin-bottom: 1rem
        & > div
            width: 100%
    .title
        margin: 0
        padding-bottom: 10px
    .row
        padding: 14px 0
        border-bottom: 1px solid rgb(226, 232, 240)
        font-size: 16px
        h2, div
            margin: 0 0 0.5rem
        .question
            font-size: 20px
            display: flex
            align-items: center
            gap: 8px
        .answer
            color: rgb(71, 85, 105)
            padding-left: 24px
        .icon
            width: 16px
            height: 16px
        .topic
            display: flex
            list-style: none
            padding-left: 24px
            margin: 0
            font-size: 14px
            color: #737791cf
            & li
                cursor: pointer
                &:hover
                    color: #737791
            & li:not(:last-child)
                &::after
                    content: '·'
                    padding: 0.5rem
